<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_hierarquia"
        newPath="/hierarchy/new"
        editPath="/hierarchy/"
        :expander="false"
        subtitle="Hierarquia"
        tooltip="Cadastro hierarquia"
        nomeTelaDoManual="hierarchy-list"
        origemHistoricoAcao="/customer/hierarchies"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="customer_hierarchies.id" header="Cod."></Column>
            <Column field="cliente.name" :sortable="true" sortField="cliente.name" header="Cliente"></Column>
            <Column field="branch.name" :sortable="true" sortField="branch.name" header="Unidade"></Column>
            <Column field="department.name" :sortable="true" sortField="department.name" header="Setor">
                <template #body="slotProps">
                    {{ slotProps.data.department.name }}
                    <small class="block text-500">
                        {{ slotProps.data.descriptionDepartment }}
                    </small>
                </template>
            </Column>
            <Column field="position.name" :sortable="true" sortField="position.name" header="Cargo">
                <template #body="slotProps">
                    {{ slotProps.data.position.name }}
                    <small class="block text-500">
                        {{ slotProps.data.description }}
                    </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    mounted() {
        this.$service = new BaseService('/customer/hierarchies');
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customer/hierarchies');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
